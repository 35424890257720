.issue-modal {
  .ant-modal-content {
    border-radius: 12px;
    padding: 10px;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
    color: #111827;
  }

  .modal-section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .modal-label {
    font-size: 14px;
    font-weight: 600;
    color: #374151;
  }

  .modal-text {
    font-size: 14px;
    color: #4b5563;
  }

  .quantity-label {
    font-size: 14px;
    font-weight: 600;
    color: #111827;
    margin-right: 10px;
  }

  .bales {
    font-weight: 600;
    font-size: 14px;
    color: #4b5563;
  }

  .modal-attachments {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 8px;
  }

  .attachment-image {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    object-fit: cover;
  }

  .pdf-attachment {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #f3f4f6;
    font-size: 14px;
    font-weight: 500;
    color: #4b5563;
    gap: 4px;
  }

  .pdf-icon {
    width: 16px;
    height: 16px;
  }

  .shift-up {
    margin-top: -12px;
  }

  .font-wt {
    font-weight: 600;
  }
}
.issue-details-container {
  display: flex;
  align-items: baseline;
}

.issue-detail-info-icon-container img {
  margin-left: 16px;
}

.pdf-icon-container{
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid #9e9ddc;
  background-color: #ececfc;
  position: relative;
}
.pdf-icon-container img{
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 0;
  left: 20%;
}
