.attachment-modal {
  max-width: 900px !important;
  max-height: 900px;
}

.pdf-attachment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 12px;
}

.pdf-image {
  width: 120px;
  height: auto;
}

.download-link {
  border: 0.5px solid #0067e2;
  background: #f2f8ff;
  padding: 2px 12px;
  border-radius: 15px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
}

.download-text {
  font-size: 12px;
  color: #2a66ff;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 0;
}
.download-image {
  width: 16px;
  height: 16px;
}
